import { createContext, useContext, useState } from "react";

export const NotificationContext = createContext(null);

export const useNotification = () => {
  const ctx = useContext(NotificationContext);

  if (!ctx) {
    throw new Error("useNotification must be used within the NotificationProvider");
  }

  return ctx;
};

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const pushNotification = (type, message, err) => {
    let text;
    if (err) {
      const errResData = err?.response?.data;
      if (errResData?.data?.validation_error) {
        text = errResData.data.validation_error;
      } else if (errResData?.data?.message) {
        text = errResData.data.message;
      } else if (errResData?.message && errResData?.error_code) {
        text = `${errResData.message} (${errResData.error_code})`;
      } else if (errResData?.message) {
        text = errResData.message;
      } else {
        text = err.message || "Unknown Error";
      }
    } else {
      text = message;
    }

    setNotifications((notifications) => [
      ...notifications,
      {
        id: new Date().getTime(),
        type,
        message: text,
      },
    ]);
  };

  const popNotification = (id) => {
    setNotifications((notifications) => {
      return notifications.filter((notification) => notification.id !== id);
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        pushNotification,
        popNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
