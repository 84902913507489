import { useCallback, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, createSearchParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import StatementDetailPageTable from "components/StatementDetailPageTable";
import StatementDetailPageTableInfo from "components/StatementDetailPageTableInfo";

import { useStatement } from "contexts/statement";

const defaultParams = {
  page: 1,
  limit: 25,
};

const StatementDetailPageListContainer = () => {
  // Gets default value from query params.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
    defaultParams.page = page;
  }

  const navigate = useNavigate();
  const [params, setParams] = useState(defaultParams);
  const { id } = useParams();
  const { getStatementPageList, deleteStatementPage, statementPageList } = useStatement();

  const getListPage = useCallback(
    async (newParams = null) => {
      const tempParams = params;

      if (newParams) {
        setParams((prev) => ({
          ...prev,
          ...newParams,
        }));
      }

      await getStatementPageList(
        {
          ...tempParams,
          ...newParams,
        },
        id,
      );
    },
    [params],
  );

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    getListPage({
      page: newPage,
    });

    // Updates query params for browser history.
    const params = new URLSearchParams(location.search);
    params.set("page", newPage);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  const getNewPageLink = () => {
    const copyFrom = statementPageList?.data?.[0]?.uuid || "";
    return `/statement/${id}/new-page?copyFrom=${copyFrom}`;
  };

  const handleStatementPageDeleted = async (e, item) => {
    e.preventDefault();
    if (!item?.uuid) return;
    const res = await deleteStatementPage(item.uuid);

    if (res) await getListPage();
  };

  useEffect(() => {
    getListPage();
  }, []);

  return (
    <div className="page-list">
      <div className="d-flex mb-4">
        <div className="me-auto">
          <h4>Page List</h4>
        </div>
        <div>
          <Button variant="outline-success" as={Link} to={getNewPageLink()}>
            New Page
          </Button>
        </div>
      </div>
      <StatementDetailPageTable onStatementPageDeleted={handleStatementPageDeleted} />
      <StatementDetailPageTableInfo onPageChange={handlePageChange} limit={params.limit} />
    </div>
  );
};

export default StatementDetailPageListContainer;
