import { useEffect, useCallback, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { usePartnerLog } from "contexts/partner_log";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
};

const height = 300;

const DailyChart = () => {
  const { getPartnerLogChart, partnerLogDailyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("daily");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogDailyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogMonthlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const YearlyChart = () => {
  const { getPartnerLogChart, partnerLogYearlyChart, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={partnerLogYearlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyTimeChart = () => {
  const { getPartnerLogChart, partnerLogMonthlyTimeCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_time");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogMonthlyTimeCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyTimeChart = () => {
  const { getPartnerLogChart, partnerLogYearlyTimeCharts, chartLoading } = usePartnerLog();
  const getChart = useCallback(async (type) => {
    await getPartnerLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_time");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {partnerLogYearlyTimeCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const DashboardChart = () => {
  const [key, setKey] = useState("tab-na");
  return (
    <>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
        <Tab eventKey="tab-na" title="N/A"></Tab>
        <Tab eventKey="tab-daily" title="Daily">
          <DailyChart />
        </Tab>
        <Tab eventKey="tab-monthly" title="Monthly">
          <MonthlyChart />
        </Tab>
        <Tab eventKey="tab-yearly" title="Yearly">
          <YearlyChart />
        </Tab>
        <Tab eventKey="tab-monthly-time" title="Monthly Busy Hour">
          <MonthlyTimeChart />
        </Tab>
        <Tab eventKey="tab-yearly-time" title="Yearly Busy Hour">
          <YearlyTimeChart />
        </Tab>
      </Tabs>
    </>
  );
};

export default DashboardChart;
