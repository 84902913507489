import { useStatement } from "contexts/statement";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import "./index.css";

const DashboardTableInfo = (props) => {
  const { onPageChange, limit } = props;
  const { mergeStatementList } = useStatement();
  const navigate = useNavigate();
  const location = useLocation();

  const startDataCount = (mergeStatementList?.meta.currentPage - 1) * limit + 1;
  const finishDataCount = startDataCount + (mergeStatementList?.meta.totalDataPerPage - 1);

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    onPageChange({
      page: newPage,
    });

    // Updates query params for browser history.
    const params = new URLSearchParams(location.search);
    params.set("page", newPage);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  return (
    <Row>
      <Col>
        <small>
          Showing {startDataCount} - {finishDataCount} from {mergeStatementList.meta.totalData} entries
        </small>
      </Col>
      <Col className="d-flex justify-content-end">
        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            pageCount={mergeStatementList.meta.totalPage}
            pageRangeDisplayed={5}
            previousLabel="<"
            nextLabel=">"
            onPageChange={handlePageChange}
            forcePage={mergeStatementList.meta.currentPage - 1}
          />
        </div>
      </Col>
    </Row>
  );
};

export default DashboardTableInfo;
