import { useState } from "react";
import { Col, Image, Row, Button, Form, Alert } from "react-bootstrap";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "contexts/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "components/Common/CustomInput";

const Login = () => {
  const [credential, setCredential] = useState({
    username: "",
    password: "",
    token_code: "",
  });
  const [show2FAForm, set2FAForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isAuthenticated, response, loading, preLogin } = useAuth();
  const redirectUrl = new URLSearchParams(location.search).get("redirect") || "/statement-dashboard";

  const handleInput = (e) => {
    e.preventDefault();

    setCredential((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await preLogin({
      username: credential.username,
      password: credential.password,
    });

    if (res.status === 202) {
      set2FAForm(true);
    } else if (res.status === 200) {
      navigate(redirectUrl);
    }
  };

  const handleSubmitLoginWith2FA = async (e) => {
    e.preventDefault();
    const res = await login(credential);

    if (res) navigate(redirectUrl);
  };

  if (isAuthenticated) return <Navigate to="/" replace />;

  return (
    <Row className="align-items-center mx-0">
      <Col md={6} className="d-none d-md-block px-0">
        <Image src="/img/bg-login.png" fluid className="object-fit-cover vh-100" />
      </Col>
      <Col md={{ span: 4, offset: 1 }}>
        <Row>
          <Col xs={12} className="d-sm-block d-md-none mt-5"></Col>
          <Col xs={{ offset: 1, span: 10 }} md={{ offset: 1, span: 10 }}>
            <h3 className="mb-3">Welcome to Fineksi</h3>
            {!show2FAForm ? (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="username"
                    type="email"
                    placeholder="Enter your email"
                    autoFocus
                    autoComplete="off"
                    onChange={handleInput}
                    value={credential.username}
                    disabled={loading}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <CustomInput
                    name="password"
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    value={credential.password}
                    required={true}
                    autoComplete="off"
                    onChange={handleInput}
                    icon={<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />}
                    clickableIcon
                    onIconClick={() => setShowPassword(!showPassword)}
                    disabled={loading}
                  />
                </Form.Group>

                {!loading && response.message && (
                  <Alert variant="danger" className="mb-3">
                    <p className="text-danger fw-bold">
                      <FontAwesomeIcon icon={faCircleExclamation} size="lg" className="me-2" />
                      <span>{response.message}</span>
                    </p>
                  </Alert>
                )}

                <Button variant="primary" type="submit" disabled={loading}>
                  Login
                </Button>
              </Form>
            ) : (
              <Form onSubmit={handleSubmitLoginWith2FA}>
                <Form.Group className="mb-3">
                  <Form.Label>Two-factor Authentication Code</Form.Label>
                  <CustomInput
                    name="token_code"
                    type="text"
                    placeholder="Enter your 2FA code"
                    minLength={6}
                    autoFocus
                    autoComplete="off"
                    onChange={handleInput}
                    value={credential.token_code}
                    disabled={loading}
                    required
                    onlyAcceptNumbers
                  />
                </Form.Group>

                {!loading && response.message && (
                  <Alert variant="danger" className="mb-3">
                    <p className="text-danger fw-bold">
                      <FontAwesomeIcon icon={faCircleExclamation} size="lg" className="me-2" />
                      <span>{response.message}</span>
                    </p>
                  </Alert>
                )}

                <Button variant="primary" type="submit" disabled={loading}>
                  Login
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
