import { useStatement } from "contexts/statement";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";

const StatementDetailInfoContainer = () => {
  const { statement, loading } = useStatement();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="info">
      <h4 className="mb-4">Detail Information</h4>
      <Row className="mb-2">
        <Col xs={2}>
          <p>File Name</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <a href={statement?.fileUrl} target="_blank" rel="noreferrer">
              <p>{statement?.fileName || "-"}</p>
            </a>
          )}
        </Col>
      </Row>
      {statement?.password && (
        <Row className="mb-2">
          <Col xs={{ offset: 2, span: 4 }} className="text-left">
            <InputGroup>
              <Form.Control type={showPassword ? "text" : "password"} readOnly value={statement?.password || ""} />
              <InputGroup.Text className="p-0">
                <Button variant="link" onClick={handleShowPassword} className="text-secondary">
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col xs={2}>
          <p>Created At</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statement?.createdAt || "-"}</p>}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Updated At</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statement?.updatedAt || "-"}</p>}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Processed At</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statement?.processedAt || "-"}</p>}
        </Col>
      </Row>
    </div>
  );
};

export default StatementDetailInfoContainer;
