import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Tab, Tabs } from "react-bootstrap";

import Breadcrumb from "components/Breadcrumb";
import Footer from "components/Footer";
import NavigationBar from "components/NavigationBar";
import StatementDSInfo from "components/StatementDSInfo";
import StatementDSFraudTable from "components/StatementDSFraudTable";

import { useStatement } from "contexts/statement";
import { useState } from "react";

const StatementDS = () => {
  const { id } = useParams();
  const { statementDS } = useStatement();

  const breadcrumbItems = [
    { link: `/merge-statement/${statementDS?.mergeUuid}`, text: `Merge Statement` },
    { link: `/statement/${statementDS?.statementUuid}`, text: "Statement" },
    { link: `/statement-ds/${id}`, text: "Statement DS" },
  ];

  // Sets default active tab on first load.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get("tab");

  // Tabs state.
  const [key, setKey] = useState(queryTab || "tab-fraud");

  // Tabs event.
  const handleTabSelect = (key) => {
    setKey(key);

    // Updates tab for browser history.
    const params = new URLSearchParams(location.search);
    params.set("tab", key);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <StatementDSInfo />

        <Tabs activeKey={key} onSelect={handleTabSelect} className="my-3" fill mountOnEnter>
          <Tab eventKey="tab-analysis" title={<h4>Analysis</h4>}>
            <h1>Coming Soon</h1>
          </Tab>
          <Tab eventKey="tab-eod" title={<h4>EOD</h4>}>
            <h1>Coming Soon</h1>
          </Tab>
          <Tab eventKey="tab-fraud" title={<h4>Fraud</h4>}>
            <StatementDSFraudTable />
          </Tab>
          <Tab eventKey="tab-kt" title={<h4>Kategorisasi</h4>}>
            <h1>Coming Soon</h1>
          </Tab>
        </Tabs>
      </Container>
      <Footer />
    </>
  );
};

export default StatementDS;
