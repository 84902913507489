import { Button, Form, Table } from "react-bootstrap";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStatement } from "contexts/statement";
import { transformCamelToSnakeInArray } from "utils/helper";

const tableHeader = (
  <tr>
    <th style={{ width: "1%" }}>#</th>
    <th style={{ width: "145px" }}>Type</th>
    <th>Description</th>
    <th style={{ width: "100px" }} className="text-center">
      Indicator
    </th>
    <th style={{ width: "100px" }} className="text-end">
      Count
    </th>
  </tr>
);

const StatementDSFraudTable = () => {
  const { id } = useParams();
  const { getStatementDSFraudList, putStatementSaveDSFraud, statementDSFraudList, loading } = useStatement();
  const { Control: Input, Switch } = Form;
  const [fraudList, setFraudList] = useState([]);

  const onDataChange = (e, index, type) => {
    const selectedData = fraudList[index];
    if (selectedData) {
      if (type === "indicator") {
        selectedData[type] = e.target.checked;
        if (selectedData[type] && Number(selectedData.count) === 0) {
          selectedData.count = 1;
        } else if (!selectedData[type] && Number(selectedData.count) !== 0) {
          selectedData.count = 0;
        }
      } else if (type === "count") {
        let value = Number(e.target.value);
        value = Number.isNaN(value) || value < 0 ? 0 : value;
        selectedData[type] = value;
        if (selectedData[type] === 0 && selectedData.indicator) {
          selectedData.indicator = false;
        } else if (selectedData[type] > 0 && !selectedData.indicator) {
          selectedData.indicator = true;
        }
      } else {
        selectedData[type] = e.target.value;
      }

      fraudList.splice(index, 1, selectedData);
      setFraudList([...fraudList]);
    }
  };

  const handleSave = async () => {
    const filterList = fraudList
      .filter((x) => !(x.indicator === x.original?.indicator && x.count === x.original?.count))
      .map((x) => ({
        uuid: x.uuid,
        indicator: x.indicator ? 1 : 0,
        count: x.count,
      }));
    const res = await putStatementSaveDSFraud(id, transformCamelToSnakeInArray(filterList));
    if (res) await getStatementDSFraudList(id);
  };

  useEffect(() => {
    getStatementDSFraudList(id);
  }, [id]);

  useEffect(() => {
    setFraudList(
      statementDSFraudList.data.map((x) => ({
        uuid: x.uuid,
        type: x.type,
        description: x.description,
        indicator: !!x.indicator,
        count: x.count,
        original: {
          indicator: !!x.indicator,
          count: x.count,
        },
      })),
    );
  }, [statementDSFraudList]);

  return (
    <>
      <Table responsive bordered striped hover size="sm" className="mb-3">
        <thead>{tableHeader}</thead>
        <tbody>
          {fraudList.map((item, index) => (
            <tr key={index}>
              <td className="align-middle">{index + 1}</td>
              <td className="align-middle">{item.type}</td>
              <td className="align-middle">{item.description}</td>
              <td className="align-middle text-center">
                <Switch checked={item.indicator} onChange={(e) => onDataChange(e, index, "indicator")} />
              </td>
              <td className="align-middle text-right">
                <Input
                  type="number"
                  style={{ textAlign: "right" }}
                  value={Number(item.count)}
                  onChange={(e) => onDataChange(e, index, "count")}
                  step={1}
                  min={0}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-end">
        <Button variant="outline-primary" onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </div>
    </>
  );
};

export default StatementDSFraudTable;
