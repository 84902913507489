import { faBookmark, faClock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFineTuningLog } from "contexts/finetuning_log";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const DashboardTable = () => {
  const { fineTuningLogList, loading } = useFineTuningLog();

  const startDataCount = (fineTuningLogList.meta.currentPage - 1) * fineTuningLogList.meta.totalDataPerPage + 1;

  return (
    <>
      {loading ? (
        <Skeleton height={250} className="rounded" />
      ) : (
        fineTuningLogList?.data?.map((item, index) => (
          <Row key={index} className="mb-3">
            <Col md={1} className="center d-flex flex-column justify-content-start align-items-end">
              {startDataCount + index}
            </Col>
            <Col
              md={11}
              className={{
                "bg-secondary bg-opacity-25": index % 2 === 0,
              }}
            >
              <div className={`border-start border-2 ps-3 border-secondary`}>
                <FontAwesomeIcon icon={faClock} className="me-2" />
                <strong>
                  {item.createdAt} ({item.createdAtAgo})
                </strong>
                <p>
                  <FontAwesomeIcon icon={faBookmark} className="me-2" />
                  {item.action} at{" "}
                  {item.sourceUrl && item.sourceRefId && (
                    <>
                      <a href={item.sourceUrl} className="decoration-none">
                        {item.sourceType}
                      </a>
                      <span className="ms-2">({item.sourceRefId})</span>
                    </>
                  )}
                  {(!item.sourceUrl || !item.sourceRefId) && <>{item.sourceType}</>}
                </p>
                <small>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  {item.actionBy?.name}
                </small>
              </div>
            </Col>
          </Row>
        ))
      )}
    </>
  );
};

export default DashboardTable;
