import caseparser from "caseparser";

export const transformSnakeToCamelInArray = (array) => {
  let result = [];

  result = array.map((x) => {
    const temp = {};

    Object.keys(x).map((y) => {
      const newKey = caseparser.snakeToCamel(y);
      temp[newKey] = x[y];

      if (Array.isArray(temp[newKey])) {
        temp[newKey] = transformSnakeToCamelInArray(temp[newKey]);
      }

      return y;
    });

    return { ...temp };
  });

  return result;
};

export const transformSnakeToCamelObject = (object) => {
  const result = {};

  Object.keys(object).map((key) => {
    const newKey = caseparser.snakeToCamel(key);
    result[newKey] = object[key];

    if (Array.isArray(result[newKey])) {
      result[newKey] = transformSnakeToCamelInArray(result[newKey]);
    } else if (typeof result[newKey] === "object" && result[newKey] !== null) {
      result[newKey] = transformSnakeToCamelObject(result[newKey]);
    }

    return key;
  });

  return result;
};

export const transformSnakeToText = (text) => text.split("_").join(" ");

export const transformCameltoSnakeObject = (object) => {
  const result = {};

  Object.keys(object).map((key) => {
    const newKey = caseparser.camelToSnake(key);
    result[newKey] = object[key];

    return key;
  });

  return result;
};

export const transformCamelToSnakeInArray = (array) => {
  let result = [];

  result = array.map((x) => {
    const temp = {};

    Object.keys(x).map((y) => {
      const newKey = caseparser.camelToSnake(y);
      temp[newKey] = x[y];

      if (Array.isArray(temp[newKey])) {
        temp[newKey] = transformCamelToSnakeInArray(temp[newKey]);
      } else if (typeof result[newKey] === "object" && result[newKey] !== null) {
        result[newKey] = transformCameltoSnakeObject(result[newKey]);
      }

      return y;
    });

    return { ...temp };
  });

  return result;
};

export const chartColors = {
  blue: "#0d6efd",
  red: "#dc3545",
  green: "#198754",
  indigo: "#6610f2",
  pink: "#d63384",
  teal: "#20c997",
  purple: "#6f42c1",
  orange: "#fd7e14",
  yellow: "#ffc107",
  cyan: "#0dcaf0",
  gray: "#adb5bd",
};

export const transformChartToMultiDatasets = (
  resData,
  stackKey = "partner_name",
  dataKey = "processed_usage_count",
) => {
  const groupData = resData.reduce((acc, item) => {
    if (!acc[item.period_key]) {
      acc[item.period_key] = [];
    }

    acc[item.period_key].push(item);
    return acc;
  }, {});

  const colors = Object.values(chartColors);

  const datasets = [];
  Object.values(groupData).forEach((group) => {
    const dataset = {
      labels: group.map((x) => x[stackKey]),
      datasets: [
        {
          label: group[0].period_label,
          data: group.map((x) => x[dataKey]),
          backgroundColor: group.map((_, idx) => colors[idx % colors.length]),
        },
      ],
    };
    datasets.push({
      title: group[0].period_label,
      dataset,
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            position: "bottom",
            display: true,
            text: group[0].period_label,
          },
        },
      },
    });
  });

  return datasets;
};

export const transformChartToStackGroupDataset = (resData, stackKey = "admin_name", dataKey = "count") => {
  const periodGroup = resData.reduce((acc, item) => {
    if (!acc[item.period_key]) {
      acc[item.period_key] = item.period_label;
    }

    return acc;
  }, {});

  let colorIndex = 0;
  const colors = Object.values(chartColors);

  // Periods.
  const periods = Object.entries(periodGroup).map(([key, label]) => ({ key, label }));
  periods.sort((a, b) => {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });
  const periodKeys = periods.map((x) => x.key);

  const groupDatasets = {};
  const datasets = [];
  resData.forEach((item) => {
    let stackDataset;
    const stackLabel = `${item[stackKey]}`;
    if (!groupDatasets[stackLabel]) {
      const keyCountPairs = periodKeys.reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
      }, {});
      stackDataset = {
        label: stackLabel,
        keyCountPairs,
        backgroundColor: colors[colorIndex],
        stack: item[stackKey],
      };
      groupDatasets[stackLabel] = stackDataset;
      datasets.push(stackDataset);
      colorIndex = (colorIndex + 1) % colors.length;
    } else {
      stackDataset = groupDatasets[stackLabel];
    }

    stackDataset.keyCountPairs[item.period_key] = item[dataKey];
  });

  const newDatasets = [];
  datasets.forEach((ds) => {
    const data = Object.values(ds.keyCountPairs);
    const newDs = {
      label: ds.label,
      data,
      backgroundColor: ds.backgroundColor,
      stack: ds.stack,
    };
    newDatasets.push(newDs);
  });

  const labels = periods.map((x) => x.label);
  return {
    labels,
    datasets: newDatasets,
  };
};
