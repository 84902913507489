import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStatement } from "contexts/statement";
import { transformSnakeToText } from "utils/helper";
import { Badge, Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const tableHeader = (
  <tr>
    <th style={{ width: "1%", minWidth: "30px", verticalAlign: "top" }}>#</th>
    <th style={{ verticalAlign: "top" }}>Partner / File Name</th>
    <th style={{ width: "120px", verticalAlign: "top" }}>Processed At</th>
    <th style={{ width: "110px", verticalAlign: "top" }}>Ready At</th>
    <th style={{ width: "110px", verticalAlign: "top" }}>Created At</th>
    <th style={{ width: "10%", verticalAlign: "top" }}>Type / Source</th>
    <th style={{ width: "160px", verticalAlign: "top" }}>Status</th>
    <th style={{ width: "7.5%", verticalAlign: "top" }}>Action</th>
  </tr>
);

const DashboardTable = () => {
  const { mergeStatementList, loading } = useStatement();

  const startDataCount = (mergeStatementList.meta.currentPage - 1) * mergeStatementList.meta.totalDataPerPage + 1;

  const tuningStatus = (status) => {
    let bg = "";
    let color = "";

    switch (status) {
      case "marked_as_good":
        bg = "success";
        break;
      case "marked_as_error":
        bg = "danger";
        break;
      case "started":
        bg = "warning";
        color = "text-dark";
        break;
      default:
        bg = "secondary";
    }

    return (
      <Badge bg={bg} className={color} pill>
        {transformSnakeToText(status)}
      </Badge>
    );
  };

  const typeStatus = (type) => {
    let bg = "";
    let color = "";

    switch (type) {
      case "sme":
        bg = "success";
        break;
      default:
        bg = "danger";
    }

    return (
      <Badge bg={bg} className={color} pill>
        {transformSnakeToText(type)}
      </Badge>
    );
  };

  const sourceBadge = (type) => {
    let bg = "";
    let color = "";

    switch (type) {
      case "api":
        bg = "info";
        break;
      case "dashboard":
        bg = "primary";
        break;
      default:
        bg = "secondary";
    }

    return (
      <Badge bg={bg} className={color} pill>
        {transformSnakeToText(type)}
      </Badge>
    );
  };

  const statusBadge = (status) => {
    let bg = "";
    let color = "";

    switch (status) {
      case "completed":
        bg = "success";
        break;
      case "failed":
        bg = "danger";
        break;
      case "error":
        bg = "danger";
        break;
      case "processing":
        bg = "warning";
        color = "text-dark";
        break;
      case "in_progress":
        bg = "warning";
        color = "text-dark";
        break;
      default:
        bg = "secondary";
    }

    return (
      <Badge bg={bg} className={color} pill>
        {transformSnakeToText(status)}
      </Badge>
    );
  };

  return (
    <Table responsive bordered striped hover size="sm" className="mb-4">
      <thead>{tableHeader}</thead>
      <tbody>
        {loading
          ? Array.from(Array(5).keys()).map((val1) => (
              <tr key={val1}>
                {Array.from(Array(9).keys()).map((val2) => (
                  <td key={`${val1}${val2}`}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          : mergeStatementList.data.map((item, index) => (
              <tr key={item.uuid}>
                <td className="text-end pe-2">{startDataCount + index}</td>
                <td>
                  <small>{item.partnerName}</small>
                  <br />
                  <small>{item.fileName}</small>
                </td>
                <td>{item.processedAt}</td>
                <td>{item.readyAt}</td>
                <td>{item.createdAt}</td>
                <td>
                  <span className="text-capitalize">{item?.type ? typeStatus(item.type) : ""}</span>
                  <br />
                  <span className="text-capitalize">{item?.source ? sourceBadge(item.source) : ""}</span>
                </td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-api-status-${index}`}>Status (Rekening Koran)</Tooltip>}
                  >
                    <span>RK: </span>
                  </OverlayTrigger>
                  <span className="text-capitalize">{item?.status ? statusBadge(item.status) : ""}</span>

                  <br />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-api-status-${index}`}>Fine Tuning Status</Tooltip>}
                  >
                    <span>FT: </span>
                  </OverlayTrigger>
                  <span className="text-capitalize">
                    {item?.fineTuningStatus ? tuningStatus(item.fineTuningStatus) : ""}
                  </span>

                  <br />
                  {item?.source === "api" && (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-api-status-${index}`}>Callback Status</Tooltip>}
                      >
                        <span>CB: </span>
                      </OverlayTrigger>
                      <span className="text-capitalize">
                        {item?.callbackStatus ? statusBadge(item.callbackStatus) : ""}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
                    <Button variant="link" className="px-1" as={Link} to={`../merge-statement/${item.uuid}`}>
                      <FontAwesomeIcon icon={faEye} className="text-primary pointer-event" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
};

export default DashboardTable;
