import { useCallback, useEffect, useState } from "react";
import PartnerLogDashboardTable from "../PartnerLogDashboardTable";
import PartnerLogDashboardTableInfo from "../PartnerLogDashboardTableInfo";
import { useLocation } from "react-router-dom";
import { usePartnerLog } from "contexts/partner_log";

const defaultParams = {
  page: 1,
  limit: 25,
};

const Dashboard = () => {
  // Gets default value from query params.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
    defaultParams.page = page;
  }

  const [params, setParams] = useState(defaultParams);
  const { getPartnerLogList } = usePartnerLog();

  const getList = useCallback(
    async (newParams = null) => {
      const tempParams = params;

      if (newParams) {
        setParams((prev) => ({
          ...prev,
          ...newParams,
        }));
      }

      await getPartnerLogList({
        ...tempParams,
        ...newParams,
      });
    },
    [params],
  );

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <PartnerLogDashboardTableInfo onPageChange={getList} limit={params.limit} />
      <PartnerLogDashboardTable />
      <PartnerLogDashboardTableInfo onPageChange={getList} limit={params.limit} />
    </>
  );
};

export default Dashboard;
