import { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "react-bootstrap";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSlik } from "contexts/slik";

const Info = () => {
  const { id } = useParams();
  const { loading, getMergeSlik, mergeSlik } = useSlik();

  const getDetail = useCallback(async () => {
    await getMergeSlik(id);
  }, []);

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <div className="info">
        <Row className="mb-3">
          <Col>
            <h4 className="mb-4">Detail Information</h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>File Name</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeSlik?.fileName || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Partner</p>
          </Col>
          <Col xs={4}>{loading ? <Skeleton /> : <p>{mergeSlik?.partnerName || "-"}</p>}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Created At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeSlik?.createdAt || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Updated At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeSlik?.updatedAt || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Processed At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeSlik?.processedAt || "-"}</p>}
          </Col>
        </Row>
      </div>
      <hr />
      <div className="processed-files">
        <h4 className="mb-4">Processed Files</h4>
        <div className="d-flex align-items-center gap-4 flex-wrap">
          {loading
            ? Array.from(Array(3).keys()).map((_, index) => (
                <div className="file d-flex flex-column justify-content-center align-items-center rounded" key={index}>
                  <FontAwesomeIcon icon={faFile} size="3x" className="mb-2 text-muted" />
                </div>
              ))
            : mergeSlik?.processedFiles.map((file, index) => (
                <Row key={index}>
                  <Col xs="auto" className="text-center">
                    <a
                      href={file.htmlUrl}
                      target="_blank"
                      className="file d-flex flex-column justify-content-center align-items-center rounded"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFile} size="3x" className="mb-2 text-muted" />
                      <p className="text-uppercase fw-bold">{file.name}</p>
                    </a>

                    <a href={file.url} target="_blank" rel="noreferrer">
                      Download
                    </a>
                  </Col>
                </Row>
              ))}
        </div>
      </div>
    </>
  );
};

export default Info;
