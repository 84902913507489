import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={6} className="text-center">
          <h1>404 - Page Not Found</h1>
          <p>Oops, the page you&apos;re looking for does not exist.</p>
          <Button variant="primary" as={Link} to="/">
            Go Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
