import { faBookmark, faClock, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePartnerLog } from "contexts/partner_log";
import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const DashboardTable = () => {
  const { partnerLogList, loading } = usePartnerLog();

  const startDataCount = (partnerLogList.meta.currentPage - 1) * partnerLogList.meta.totalDataPerPage + 1;

  return (
    <>
      {loading ? (
        <Skeleton height={250} className="rounded" />
      ) : (
        partnerLogList?.data?.map((item, index) => (
          <Row key={index} className="mb-3">
            <Col md={1} className="center d-flex flex-column justify-content-start align-items-end">
              {startDataCount + index}
            </Col>
            <Col
              md={11}
              className={{
                "bg-secondary bg-opacity-25": item.alternate,
              }}
            >
              <div className={`border-start border-2 ps-3 border-secondary`}>
                <div>
                  <FontAwesomeIcon icon={faClock} className="me-2" />
                  <strong>
                    {item.createdAt} ({item.createdAtAgo})
                  </strong>
                </div>
                <div>
                  <FontAwesomeIcon icon={faTag} className="me-2" />
                  {item.type} / {item.message}
                </div>
                <div>
                  <FontAwesomeIcon icon={faBookmark} className="me-2" />
                  {[item.key1, item.key2, item.key3].filter((x) => x).join(" / ")}
                </div>
                {item.value && (
                  <OverlayTrigger
                    trigger="focus"
                    placement="right"
                    overlay={
                      <Popover style={{ minWidth: "65%" }}>
                        <pre className="p-4 m-0">{JSON.stringify(item.value, null, 2)}</pre>
                      </Popover>
                    }
                  >
                    <Button variant="link" className="ms-3">
                      View Data
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </Col>
          </Row>
        ))
      )}
    </>
  );
};

export default DashboardTable;
