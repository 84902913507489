import { createContext, useContext, useState } from "react";
import { getFineTuningLogList, getFineTuningLogChart } from "utils/api";
import {
  transformSnakeToCamelInArray,
  chartColors,
  transformChartToMultiDatasets,
  transformChartToStackGroupDataset,
} from "utils/helper";
import { useNotification } from "contexts/notification";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const chartDefault = {
  labels: [],
  datasets: [],
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

export const LogContext = createContext(null);

export const useFineTuningLog = () => {
  const ctx = useContext(LogContext);

  if (!ctx) {
    throw new Error("useFineTuningLog must be used within the LogProvider");
  }

  return ctx;
};

const LogProvider = ({ children }) => {
  const { pushNotification } = useNotification();
  const [fineTuningLogList, setFineTuningLogList] = useState(paginationListDefault);
  const [fineTuningLogDailyChart, setFineTuningLogDailyChart] = useState(chartDefault);
  const [fineTuningLogMonthlyChart, setFineTuningLogMonthlyChart] = useState(chartDefault);
  const [fineTuningLogYearlyChart, setFineTuningLogYearlyChart] = useState(chartDefault);
  const [fineTuningLogDailyAdminChart, setFineTuningLogDailyAdminChart] = useState(chartDefault);
  const [fineTuningLogMonthlyAdminCharts, setFineTuningLogMonthlyAdminCharts] = useState([]);
  const [fineTuningLogYearlyAdminCharts, setFineTuningLogYearlyAdminCharts] = useState([]);
  const [response, setResponse] = useState(responseDefault);
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);

  const handleGetFineTuningLogList = async (params) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "FINETUNING_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getFineTuningLogList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setFineTuningLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetFineTuningLogChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "FINETUNING_LOG_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getFineTuningLogChart({ type });

      if (res.status === 200) {
        // set state
        if (type.endsWith("_admin")) {
          if (type === "yearly_admin") {
            const datasets = transformChartToMultiDatasets(res.data.data, "admin_name", "count");
            setFineTuningLogYearlyAdminCharts(datasets);
          } else if (type === "monthly_admin") {
            const datasets = transformChartToMultiDatasets(res.data.data, "admin_name", "count");
            setFineTuningLogMonthlyAdminCharts(datasets);
          } else if (type === "daily_admin") {
            const datasets = transformChartToStackGroupDataset(res.data.data, "admin_name", "count");
            setFineTuningLogDailyAdminChart(datasets);
          }
        } else {
          let fn;
          if (type === "yearly") {
            fn = setFineTuningLogYearlyChart;
          } else if (type === "monthly") {
            fn = setFineTuningLogMonthlyChart;
          } else if (type === "daily") {
            fn = setFineTuningLogDailyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const counts = res.data.data.map((x) => x.count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: type.toUpperCase(),
                  data: counts,
                  backgroundColor: chartColors.blue,
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <LogContext.Provider
      value={{
        loading,
        chartLoading,
        response,
        fineTuningLogList,
        fineTuningLogDailyChart,
        fineTuningLogMonthlyChart,
        fineTuningLogYearlyChart,
        fineTuningLogDailyAdminChart,
        fineTuningLogMonthlyAdminCharts,
        fineTuningLogYearlyAdminCharts,
        getFineTuningLogList: handleGetFineTuningLogList,
        getFineTuningLogChart: handleGetFineTuningLogChart,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogProvider;
