import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  const rootItem = { link: "/", text: "#" };

  return (
    <Container className="mb-2">
      {[rootItem, ...(items || [])].map(
        (item, index) =>
          item.link && (
            <span key={index}>
              {index > 0 && " / "}
              <Link to={item.link} style={{ textDecoration: "none" }}>
                <small>{item.text || "#"}</small>
              </Link>
            </span>
          ),
      )}
    </Container>
  );
};

export default Breadcrumb;
