import React, { useState, useImperativeHandle, forwardRef } from "react";
import { InputGroup, FormControl, Button, Alert, Accordion } from "react-bootstrap";
import "./index.scss";

/**
 * FindReplaceComponent - A React component for finding and replacing text within string fields of objects in a data array.
 *
 * @param {Object} props - The props object.
 * @param {Array<Object>} props.data - The array of objects containing data. The component only processes string data types in these objects.
 * @param {Function} props.setData - Function to update the data array after replacements.
 * @param {Array<string>} [props.includePropertyToFind=[]] - An optional array of property names that specify which fields should be included in the find and replace operations. Only string fields will be processed.
 * @param {boolean} [props.enabledSticky=true] - An optional flag to enable or disable sticky positioning for the component.
 * @param {React.Ref} ref - A React ref that can be used by the parent component to interact with the component's methods.
 *
 * @example
 * // Example of using FindReplaceComponent
 * const data = [
 *   { timestamp: "2024-01-01 18:15:39", description: "IBIZ ERDEHA MULTI N TO SUCI JAYA ABADHI", amount: "62500000.00" },
 *   { timestamp: "2024-01-02 19:25:39", description: "TRANSFER TO JOHN DOE", amount: "5000000.00" }
 * ];
 *
 * const setData = (updatedData) => { ... };
 *
 * <FindReplaceComponent
 *   data={data}
 *   setData={setData}
 *   includePropertyToFind={['description']}
 * />
 */

const FindReplaceComponent = forwardRef(
  ({ data = [], setData, includePropertyToFind = [], enabledSticky = true }, ref) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isEnabledReplace, setIsEnabledReplace] = useState(false);
    const [replaceTerm, setReplaceTerm] = useState("");
    const [matchCount, setMatchCount] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

    const escapeRegExp = (text) => {
      return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const resetComponentState = () => {
      const resetHighlightData = data.map((modifiedData) => {
        if (modifiedData.find_matches) delete modifiedData.find_matches;
        return modifiedData;
      });
      setData(resetHighlightData);
      setIsEnabledReplace(false);
      setReplaceTerm("");
      setMatchCount(0);
      setCurrentMatchIndex(0);
    };

    const normalizeValue = (value) => {
      if (value) {
        if (typeof value === "string") {
          return value.toLowerCase();
        }
        return value;
      }
      return value;
    };

    const setRegexEscapeChar = (text) => {
      const escapedSearchValue = escapeRegExp(text);
      return new RegExp(escapedSearchValue, "gi"); // Case insensitive and global
    };

    const setHighlightData = (initializeData = null) => {
      const initializeModifyData = initializeData || data;
      const regex = setRegexEscapeChar(searchTerm);
      const setHighlightOnList = initializeModifyData.map((modifiedData) => {
        const updatedModifiedData = { ...modifiedData, find_matches: {} };
        Object.keys(modifiedData).forEach((key) => {
          const value = normalizeValue(JSON.stringify(modifiedData[key]));
          if (value.match(regex)) {
            updatedModifiedData.find_matches[key] = true;
          } else {
            updatedModifiedData.find_matches[key] = false;
          }
        });
        return updatedModifiedData;
      });

      setData(setHighlightOnList);
    };

    const findAndSetMatchCount = (searchValue) => {
      let count = 0;
      const regex = setRegexEscapeChar(searchValue);

      data.forEach((modifiedData) => {
        let filteredObject = {};
        if (includePropertyToFind.length) {
          includePropertyToFind.forEach((value) => {
            filteredObject[value] = modifiedData[value];
          });
        } else {
          filteredObject = modifiedData;
        }

        Object.values(filteredObject)
          .map(normalizeValue)
          .forEach((value) => {
            const matches = value.match(regex);
            if (matches) {
              count += matches.length;
            }
          });
      });

      setIsEnabledReplace(true);
      setHighlightData();
      setMatchCount(count);
      setCurrentMatchIndex(0);
    };

    const handleFind = (value) => {
      if (searchTerm) {
        findAndSetMatchCount(value || searchTerm);
      } else {
        resetComponentState();
      }
    };

    const handleReplaceAll = () => {
      const updatedTransactions = data.map((transaction) => {
        const updatedTransaction = { ...transaction };
        const regex = setRegexEscapeChar(searchTerm);

        includePropertyToFind.forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(transaction, key) && typeof transaction[key] === "string") {
            updatedTransaction[key] = transaction[key].replace(regex, replaceTerm);
          }
        });

        return updatedTransaction;
      });

      setHighlightData(updatedTransactions);
      setMatchCount(0);
      setCurrentMatchIndex(0);
    };

    const handleReplace = () => {
      const updatedTransactions = [...data];
      let newMatchCount = matchCount;
      const regex = setRegexEscapeChar(searchTerm);

      for (let i = currentMatchIndex; i < updatedTransactions.length; i++) {
        const transaction = updatedTransactions[i];
        let replacementDone = false;

        includePropertyToFind.forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(transaction, key)) {
            const value = transaction[key];
            if (typeof value === "string" && value.match(regex)) {
              const matches = value.match(regex);
              const numberOfReplacements = matches ? matches.length : 0;
              updatedTransactions[i][key] = value.replace(regex, replaceTerm);
              replacementDone = true;
              const remainingMatches = matchCount - numberOfReplacements;
              setMatchCount(remainingMatches);
              setCurrentMatchIndex(i + 1);
              return;
            }
          }
        });

        if (replacementDone) {
          break;
        }
      }

      if (newMatchCount === 0) {
        setCurrentMatchIndex(0);
      }

      setHighlightData(updatedTransactions);
    };

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      handleFind,
      handleReplace,
      handleReplaceAll,
      resetComponentState,
    }));

    return (
      <div className={`find-replace-container${enabledSticky ? " sticky-component" : ""}`}>
        <Accordion className="mb-4" defaultActiveKey={["findAndReplace"]} alwaysOpen>
          <Accordion.Item eventKey="findAndReplace">
            <Accordion.Header>Find & Replace</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text>Find in text</InputGroup.Text>
                <FormControl
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (!e.target.value || isEnabledReplace) {
                      resetComponentState();
                    }
                  }}
                />
                <Button onClick={() => handleFind()} variant="primary">
                  Find
                </Button>
              </InputGroup>
              {matchCount > 0 && (
                <Alert variant="info">
                  Found {matchCount} match{matchCount > 1 ? "es" : ""}.
                </Alert>
              )}
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Replace with..."
                  value={replaceTerm}
                  disabled={!isEnabledReplace}
                  onChange={(e) => setReplaceTerm(e.target.value)}
                />
                <Button disabled={!isEnabledReplace} onClick={handleReplace} variant="secondary">
                  Replace
                </Button>
                <Button disabled={!isEnabledReplace} onClick={handleReplaceAll} variant="secondary">
                  Replace All
                </Button>
              </InputGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  },
);

// Set the display name for better debugging
FindReplaceComponent.displayName = "FindReplaceComponent";

export default FindReplaceComponent;
