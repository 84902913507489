import { Container } from "react-bootstrap";
import NavigationBar from "components/NavigationBar";
import PartnerLogDashboardData from "components/PartnerLogDashboardData";
import PartnerLogDashboardMeta from "components/PartnerLogDashboardMeta";
import Footer from "components/Footer";

const Dashboard = () => {
  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <h2 className="mb-4">Partner Log</h2>
        <div className="mb-4">
          <PartnerLogDashboardMeta />
        </div>
        <PartnerLogDashboardData />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
