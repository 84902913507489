import { useEffect, useCallback, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useFineTuningLog } from "contexts/finetuning_log";

const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
};

const height = 300;

const DailyChart = () => {
  const { getFineTuningLogChart, fineTuningLogDailyChart, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("daily");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={fineTuningLogDailyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyChart = () => {
  const { getFineTuningLogChart, fineTuningLogMonthlyChart, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={fineTuningLogMonthlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const YearlyChart = () => {
  const { getFineTuningLogChart, fineTuningLogYearlyChart, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={fineTuningLogYearlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const DailyAdminChart = () => {
  const { getFineTuningLogChart, fineTuningLogDailyAdminChart, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("daily_admin");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={fineTuningLogDailyAdminChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyAdminChart = () => {
  const { getFineTuningLogChart, fineTuningLogMonthlyAdminCharts, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_admin");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {fineTuningLogMonthlyAdminCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyAdminChart = () => {
  const { getFineTuningLogChart, fineTuningLogYearlyAdminCharts, chartLoading } = useFineTuningLog();
  const getChart = useCallback(async (type) => {
    await getFineTuningLogChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_admin");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {fineTuningLogYearlyAdminCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const DashboardChart = () => {
  const [key, setKey] = useState("tab-na");
  return (
    <>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
        <Tab eventKey="tab-na" title="N/A"></Tab>
        <Tab eventKey="tab-daily" title="Daily">
          <DailyChart />
        </Tab>
        <Tab eventKey="tab-monthly" title="Monthly">
          <MonthlyChart />
        </Tab>
        <Tab eventKey="tab-yearly" title="Yearly">
          <YearlyChart />
        </Tab>
        <Tab eventKey="tab-daily-time" title="Daily Ops">
          <DailyAdminChart />
        </Tab>
        <Tab eventKey="tab-monthly-time" title="Monthly Ops">
          <MonthlyAdminChart />
        </Tab>
        <Tab eventKey="tab-yearly-time" title="Yearly Ops">
          <YearlyAdminChart />
        </Tab>
      </Tabs>
    </>
  );
};

export default DashboardChart;
