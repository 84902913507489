import { useStatement } from "contexts/statement";
import { Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const StatementDetailPageTableInfo = (props) => {
  const { onPageChange, limit } = props;
  const { statementPageList } = useStatement();

  const startDataCount = (statementPageList?.meta.currentPage - 1) * limit + 1;
  const finishDataCount = startDataCount + (statementPageList?.meta.totalDataPerPage - 1);

  return (
    <Row className="mb-2">
      <Col>
        <small>
          Showing {startDataCount} - {finishDataCount} from {statementPageList?.meta.totalData} entries
        </small>
      </Col>
      <Col className="d-flex justify-content-end">
        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            pageCount={statementPageList?.meta.totalPage}
            pageRangeDisplayed={5}
            previousLabel="<"
            nextLabel=">"
            onPageChange={onPageChange}
            forcePage={statementPageList?.meta.currentPage - 1}
          />
        </div>
      </Col>
    </Row>
  );
};

export default StatementDetailPageTableInfo;
