import Cookies from "js-cookie";

const authCookieName = "token-bo";

export const getCookie = () => Cookies.get(authCookieName) || "";

export const setCookie = (token, expiryDate) => {
  Cookies.set(authCookieName, token, {
    expires: new Date(expiryDate),
  });
};

export const removeCookie = () => {
  Cookies.remove(authCookieName);
};
