import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { useStatement } from "contexts/statement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const tableHeader = (
  <tr>
    <th>#</th>
    <th>Bank Name</th>
    <th>Account Name</th>
    <th>Account Number</th>
    <th>Period</th>
    <th style={{ width: "85px" }}>Action</th>
  </tr>
);

const StatementDetailDSListContainer = () => {
  const { statement, loading } = useStatement();

  return (
    <div className="detail-statements">
      <h4 className="mb-4">Detail Statements</h4>
      <Table responsive bordered striped hover size="sm" className="mb-4">
        <thead>{tableHeader}</thead>
        <tbody>
          {loading
            ? Array.from(Array(3).keys()).map((val1) => (
                <tr key={val1}>
                  {Array.from(Array(6).keys()).map((val2) => (
                    <td key={`${val1}${val2}`}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))
            : statement?.detailStatements.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.bankName}</td>
                  <td>{item.bankAccountName}</td>
                  <td>{item.bankAccountNumber}</td>
                  <td>{item.period}</td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
                      <Button size="sm" variant="link" className="px-1" as={Link} to={`/statement-ds/${item.uuid}`}>
                        <FontAwesomeIcon icon={faEye} className="text-primary pointer-event" />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StatementDetailDSListContainer;
