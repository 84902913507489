import { Container } from "react-bootstrap";
import "./index.css";

const Footer = () => (
  <div className="footer py-2 fixed-bottom">
    <Container className="text-center">
      <p>© {new Date().getFullYear()} Fineksi</p>
    </Container>
  </div>
);

export default Footer;
