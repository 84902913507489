import { useStatement } from "contexts/statement";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const TimerClock = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const { loading, mergeStatement } = useStatement();

  const calculateTimeLeft = () => {
    const targetDate = mergeStatement?.readyAt;
    if (!targetDate) {
      return { hours: "00", minutes: "00", seconds: "00", diff: 0 };
    }

    const targetTime = new Date(targetDate).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = targetTime - currentTime;

    if (timeDifference <= 0) {
      return { hours: "00", minutes: "00", seconds: "00", diff: 0 };
    }

    const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
    const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
    const seconds = String(Math.floor((timeDifference % (1000 * 60)) / 1000)).padStart(2, "0");
    return {
      hours,
      minutes,
      seconds,
      diff: Math.floor(timeDifference / 1000),
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(mergeStatement));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mergeStatement]);

  if (!["none", "started"].includes(mergeStatement?.fineTuningStatus)) {
    return <></>;
  }

  if (loading) {
    return <Skeleton width={170} height={60} />;
  }

  if (timeLeft?.diff > 0) {
    return (
      <Row>
        <Col>
          <h3>{timeLeft?.hours}</h3>
          <span>Hour</span>
        </Col>
        <Col>
          <h3>{timeLeft?.minutes}</h3>
          <span>Min</span>
        </Col>
        <Col>
          <h3>{timeLeft?.seconds}</h3>
          <span>Sec</span>
        </Col>
      </Row>
    );
  }

  if (timeLeft?.diff === 0 && mergeStatement?.readyAt) {
    return <h3 className="text-danger">Waktu Habis</h3>;
  }
};

export default TimerClock;
