import Axios from "axios";
import Cookies from "js-cookie";

const newAxios = Axios;

newAxios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = Cookies.get("token-bo");
      if (token) {
        config.headers.Authorization = token;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default newAxios;
