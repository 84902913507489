import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Col, Spinner, ProgressBar, Card, CardBody } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNotification } from "contexts/notification";
import CustomInput from "components/Common/CustomInput";
import { useAuth } from "contexts/auth";
import { putChangePassword } from "utils/api";
import ModalBox from "components/ModalBox";

const ChangePassword = () => {
  const { logout } = useAuth();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirmation: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pushNotification } = useNotification();

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[\W_]/.test(password)) strength += 1;
    return strength;
  };

  const getPasswordStrengthLabel = (strength) => {
    switch (strength) {
      case 1:
        return "Very Weak";
      case 2:
        return "Weak";
      case 3:
        return "Moderate";
      case 4:
        return "Strong";
      case 5:
        return "Very Strong";
      default:
        return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let errorMessage = "";
      setIsSubmitting(true);
      if (!oldPassword || !newPassword || !confirmPassword) {
        errorMessage = "All fields must be filled.";
      } else if (newPassword === oldPassword) {
        errorMessage = "New password cannot be the same as the old password.";
      } else if (newPassword !== confirmPassword) {
        errorMessage = "New password and confirmation password do not match.";
      }

      if (errorMessage) {
        pushNotification("error", errorMessage);
        return;
      }

      const res = await putChangePassword({
        old_password: oldPassword.trim(),
        new_password: newPassword.trim(),
        confirm_password: confirmPassword.trim(),
      });

      if (res.status === 200) {
        pushNotification("success", res.data?.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setOpenModal(true);
      }
    } catch (e) {
      pushNotification("error", "Failed to change password", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const passwordStrength = evaluatePasswordStrength(newPassword);
  const passwordStrengthLabel = getPasswordStrengthLabel(passwordStrength);

  return (
    <div className="mt-5">
      <Card>
        <CardBody>
          <Row>
            <Col>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  <label>Old Password:</label>
                  <CustomInput
                    name="old_password"
                    placeholder="Input Old Password"
                    type={showPassword.old ? "text" : "password"}
                    value={oldPassword}
                    minLength={8}
                    required={true}
                    onChange={(e) => setOldPassword(e.target.value)}
                    icon={<FontAwesomeIcon icon={showPassword.old ? faEyeSlash : faEye} />}
                    clickableIcon
                    onIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        old: !showPassword.old,
                      })
                    }
                    inputClassName="mb-4"
                  />
                </div>
                <div>
                  <label>New Password:</label>
                  <CustomInput
                    name="new_password"
                    placeholder="Input New Password"
                    type={showPassword.new ? "text" : "password"}
                    value={newPassword}
                    minLength={8}
                    required={true}
                    onChange={(e) => setNewPassword(e.target.value)}
                    icon={<FontAwesomeIcon icon={showPassword.new ? faEyeSlash : faEye} />}
                    clickableIcon
                    onIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        new: !showPassword.new,
                      })
                    }
                    inputClassName="mb-4"
                  />
                  <div className="mb-4">
                    <ProgressBar
                      now={(passwordStrength / 5) * 100}
                      variant={passwordStrength >= 4 ? "success" : passwordStrength >= 3 ? "warning" : "danger"}
                    />
                    <small>Password Strength: {passwordStrengthLabel}</small>
                  </div>
                </div>
                <div>
                  <label>Confirm New Password:</label>
                  <CustomInput
                    name="confirm_new_password"
                    placeholder="Input Confirm New Password"
                    type={showPassword.confirmation ? "text" : "password"}
                    value={confirmPassword}
                    minLength={8}
                    required={true}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    icon={<FontAwesomeIcon icon={showPassword.confirmation ? faEyeSlash : faEye} />}
                    clickableIcon
                    onIconClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmation: !showPassword.confirmation,
                      })
                    }
                    inputClassName="mb-4"
                  />
                </div>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <span className="me-2">
                      <Spinner size="sm" animation="border" variant="white" />
                    </span>
                  )}
                  <span>Change Password</span>
                </Button>
              </form>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalBox
        show={openModal}
        title={"Success Change Password"}
        body={
          "Your password has been successfully changed. For security reasons, please click the button below to log out. You will need to log in again to apply the changes."
        }
        isShowNegativeClick={false}
        showCloseButtonModal={false}
        labelPositiveClick={"Logout"}
        variantPositiveClick={"outline-danger"}
        onPositiveClick={() => handleLogout()}
      />
    </div>
  );
};

export default ChangePassword;
