import { useEffect, useCallback, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useStatement } from "contexts/statement";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
};

const height = 300;

const DailyChart = () => {
  const { getStatementChart, statementDailyChart, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("daily");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={statementDailyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyChart = () => {
  const { getStatementChart, statementMonthlyChart, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={statementMonthlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const YearlyChart = () => {
  const { getStatementChart, statementYearlyChart, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <div style={{ height: `${height}px` }}>
          <Bar data={statementYearlyChart} options={options} />
        </div>
      )}
    </>
  );
};

const MonthlyPartnerChart = () => {
  const { getStatementChart, statementMonthlyPartnerCharts, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_partner");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {statementMonthlyPartnerCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyPartnerChart = () => {
  const { getStatementChart, statementYearlyPartnerCharts, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_partner");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {statementYearlyPartnerCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const MonthlyBankChart = () => {
  const { getStatementChart, statementMonthlyBankCharts, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("monthly_bank");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {statementMonthlyBankCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const YearlyBankChart = () => {
  const { getStatementChart, statementYearlyBankCharts, chartLoading } = useStatement();
  const getChart = useCallback(async (type) => {
    await getStatementChart(type);
  }, []);

  useEffect(() => {
    getChart("yearly_bank");
  }, []);

  return (
    <>
      {chartLoading ? (
        <Skeleton height={height} className="rounded" />
      ) : (
        <Row style={{ height: `${height}px` }}>
          {statementYearlyBankCharts.map((chart, index) => (
            <Col key={index}>
              <Pie data={chart.dataset} options={chart.options} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

const DashboardChart = () => {
  const [key, setKey] = useState("tab-na");
  return (
    <>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
        <Tab eventKey="tab-na" title="N/A"></Tab>
        <Tab eventKey="tab-daily" title="Daily">
          <DailyChart />
        </Tab>
        <Tab eventKey="tab-monthly" title="Monthly">
          <MonthlyChart />
        </Tab>
        <Tab eventKey="tab-yearly" title="Yearly">
          <YearlyChart />
        </Tab>
        <Tab eventKey="tab-monthly-partner" title="Monthly Partner">
          <MonthlyPartnerChart />
        </Tab>
        <Tab eventKey="tab-yearly-partner" title="Yearly Partner">
          <YearlyPartnerChart />
        </Tab>
        <Tab eventKey="tab-monthly-bank" title="Monthly Bank">
          <MonthlyBankChart />
        </Tab>
        <Tab eventKey="tab-yearly-bank" title="Yearly Bank">
          <YearlyBankChart />
        </Tab>
      </Tabs>
    </>
  );
};

export default DashboardChart;
