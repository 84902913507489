import { useStatement } from "contexts/statement";
import { Button, Col, Row } from "react-bootstrap";

const StatementPageTableInfo = (props) => {
  const { onSave } = props;
  const { statementTransactionList, loading } = useStatement();

  return (
    <Row className="mb-2">
      <Col>
        <small>Showing {statementTransactionList?.meta.totalData} entries</small>
      </Col>
      <Col className="d-flex justify-content-end">
        <Button variant="outline-primary" onClick={onSave} disabled={loading}>
          Save
        </Button>
      </Col>
    </Row>
  );
};

export default StatementPageTableInfo;
