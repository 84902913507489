import { Row, Col, Image, Spinner } from "react-bootstrap";
import "./index.scss";

const Guidelines2FA = ({ loadingQRCode, qrcode = "" }) => {
  return (
    <Row className="mt-4">
      <h3 className="mb-3">Enable 2FA</h3>
      {!loadingQRCode ? (
        <Col>
          <ol>
            <li>
              <Row>
                <Col md={8} lg={8} xl={8} xs={12} sm={12}>
                  <p>You will need an authenticator mobile app to complete this process, such as one of following:</p>
                  <ul>
                    <li>Google Authenticator</li>
                    <li>Microsoft Authenticator</li>
                    <li>Authy</li>
                  </ul>
                  <Image src={qrcode} className="object-fit-cover guideline-qrcode" />
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col>
                  <p>After scanning the QR code above, enter the six-digit code generated by your authenticator</p>
                </Col>
              </Row>
            </li>
          </ol>
        </Col>
      ) : (
        <Col>
          <Spinner size="lg" animation="border" variant="primary" />
        </Col>
      )}
    </Row>
  );
};

export default Guidelines2FA;
