import ModalBox from "components/ModalBox";
import { useStatement } from "contexts/statement";
import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const modalDefault = {
  show: false,
  title: "",
  body: "",
  positiveCallback: null,
  negativeCallback: null,
};

const StatementSendCallbackButton = (props) => {
  const { handleCallback, variant = "primary", status } = props;
  const [modal, setModal] = useState(modalDefault);
  const { loading } = useStatement();

  const handleActionClick = () => {
    setModal((prev) => ({
      ...prev,
      show: true,
      title: "Send Callback Result",
      positiveCallback: handleCallback,
    }));
  };

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <>
      <ModalBox
        show={modal.show}
        title={modal.title}
        body={
          <>
            <p>Are you sure you want to Send Callback the result of this statement to partner?</p>
          </>
        }
        onPositiveClick={modal.positiveCallback}
        onNegativeClick={handleCloseModal}
      />
      {status === "failed" ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Cannot send callback result because the process is failed</Tooltip>}
        >
          <span className="d-inline-block">
            <Button className="rounded-0 rounded-end" variant={variant} disabled style={{ pointerEvents: "none" }}>
              Send Callback
            </Button>
          </span>
        </OverlayTrigger>
      ) : (
        <Button variant={variant} onClick={handleActionClick} disabled={loading}>
          Send Callback
        </Button>
      )}
    </>
  );
};

export default StatementSendCallbackButton;
