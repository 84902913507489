import { useNotification } from "contexts/notification";
import { createContext, useContext, useState } from "react";
import { getAdminDetail, postLogin, postLogout, postVerify, postPreLogin } from "utils/api";
import { setCookie, removeCookie, getCookie } from "utils/cookie";

const userDefault = {
  uuid: null,
  username: null,
  name: null,
  password_expired_at: null,
  secret: null,
  two_factor_authentication: null,
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

export const AuthContext = createContext(null);

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error("useAuth must be used within the AuthProvider");
  }

  return ctx;
};

const AuthProvider = ({ children }) => {
  const [response, setResponse] = useState(responseDefault);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(userDefault);
  const [isAuthenticated, setAuthenticated] = useState(Boolean(getCookie()));
  const { pushNotification } = useNotification();

  const handlePreLogin = async (payload) => {
    let codeStatus = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "AUTH_PRE_LOGIN",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postPreLogin(payload);
      if (res.status === 200) {
        // set state
        setUser((prev) => ({
          ...prev,
          ...res.data.data.user,
        }));

        // set cookie
        setCookie(res.data.data.token, res.data.data.expired_at);

        // set auth state
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      codeStatus = res.status;
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: codeStatus,
      message,
    }));

    return { status: codeStatus };
  };

  const handleLogin = async (payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "AUTH_LOGIN",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postLogin(payload);
      if (res.status === 200) {
        // set state
        setUser((prev) => ({
          ...prev,
          ...res.data.data.user,
        }));

        // set cookie
        setCookie(res.data.data.token, res.data.data.expired_at);

        // set auth state
        setAuthenticated(true);

        isSuccess = true;
      } else {
        setAuthenticated(false);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleLogout = async () => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "AUTH_LOGOUT",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postLogout();

      if (res.status === 200) {
        removeCookie();
        setUser(userDefault);
        setAuthenticated(false);
        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleVerify = async () => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "AUTH_VERIFY",
    }));
    setLoading(true);

    // check token
    if (!getCookie()) {
      setAuthenticated(false);
      setLoading(false);
      setResponse((prev) => ({
        ...prev,
        status: isSuccess,
        message,
      }));

      return isSuccess;
    }

    // api call
    try {
      const res = await postVerify();

      if (res.status !== 200) {
        // logout
        setAuthenticated(false);
        removeCookie();
        setUser(userDefault);
      } else {
        // set state
        setUser((prev) => ({
          ...prev,
          ...res.data.data.user,
        }));

        // set cookie
        setCookie(res.data.data.token, res.data.data.expired_at);

        // set auth state
        setAuthenticated(true);

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
      setAuthenticated(false);
      removeCookie();
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetDetail = async (uuid) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "ADMIN_GET_DETAIL",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getAdminDetail(uuid);
      if (res.status === 200) {
        const { password_expired_at, secret, two_factor_authentication } = res.data.data;
        // set state
        setUser((prev) => ({
          ...prev,
          password_expired_at,
          secret,
          two_factor_authentication,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        isAuthenticated,
        response,
        login: handleLogin,
        preLogin: handlePreLogin,
        logout: handleLogout,
        verify: handleVerify,
        getDetail: handleGetDetail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
