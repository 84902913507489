import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { faClock, faBookmark, faUser } from "@fortawesome/free-regular-svg-icons";

import { useStatement } from "contexts/statement";

import "./index.scss";

const LogTable = () => {
  const { id } = useParams();
  const { loading, getMergeStatementFineTuningLogList, mergeStatementFineTuningLogList } = useStatement();

  const getTimeline = useCallback(async () => {
    await getMergeStatementFineTuningLogList(id);
  }, []);

  useEffect(() => {
    getTimeline();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton height={250} className="rounded" />
      ) : (
        mergeStatementFineTuningLogList?.data?.map((item, index) => (
          <Row key={index} className="mb-3">
            <Col md={1} className="center d-flex flex-column justify-content-start align-items-end">
              <FontAwesomeIcon icon={faCircleDot} className={`fa-lg text-dark`} />
            </Col>
            <Col md={11}>
              <div className={`border-start border-2 ps-3 border-secondary`}>
                <FontAwesomeIcon icon={faClock} className="me-2" />
                <strong>
                  {item.createdAt} ({item.createdAtAgo})
                </strong>
                <p>
                  <FontAwesomeIcon icon={faBookmark} className="me-2" />
                  {item.action} at{" "}
                  <a href={item.sourceUrl} className="decoration-none">
                    {item.sourceType}
                  </a>
                  <span className="ms-2">({item.sourceRefId})</span>
                </p>
                <small>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  {item.actionBy?.name}
                </small>
              </div>
            </Col>
          </Row>
        ))
      )}
    </>
  );
};

export default LogTable;
