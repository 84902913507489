import ModalBox from "components/ModalBox";
import { useStatement } from "contexts/statement";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const modalDefault = {
  show: false,
  title: "",
  body: "",
  positiveCallback: null,
  negativeCallback: null,
};

const MergeStatementActionButtonGroup = (props) => {
  const { onStartTuning, onExtendTuning, onMerge, onMarkAsGood, onMarkAsError } = props;
  const [modal, setModal] = useState(modalDefault);
  const { loading } = useStatement();

  const handleActionClick = (type) => {
    let tempCallback = null;
    let title = "";
    let body = "";

    switch (type) {
      case "START":
        tempCallback = onStartTuning;
        title = "Start Tuning";
        body = "Are you sure you want to Start Tuning this statement?";
        break;
      case "EXTEND":
        tempCallback = onExtendTuning;
        title = "Extend Tuning";
        body = "Are you sure you want to Extend Tuning this statement?";
        break;
      case "MERGE":
        tempCallback = onMerge;
        title = "Merge";
        body = "Are you sure you want to Merge this statement?";
        break;
      case "MARK_GOOD":
        tempCallback = onMarkAsGood;
        title = "Mark as Good";
        body = "Are you sure you want to Mark as Good this statement?";
        break;
      case "MARK_ERROR":
        tempCallback = onMarkAsError;
        title = "Mark as Error";
        body = "Are you sure you want to Mark as Error this statement?";
        break;
      default:
        break;
    }

    setModal((prev) => ({
      ...prev,
      show: true,
      title,
      body,
      positiveCallback: tempCallback,
    }));
  };

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <>
      <ModalBox
        show={modal.show}
        title={modal.title}
        body={modal.body}
        onPositiveClick={modal.positiveCallback}
        onNegativeClick={handleCloseModal}
      />
      <ButtonGroup>
        <Button variant="outline-secondary" onClick={() => handleActionClick("START")} disabled={loading}>
          Start Tuning
        </Button>
        <Button variant="outline-secondary" onClick={() => handleActionClick("EXTEND")} disabled={loading}>
          Extend Tuning
        </Button>
        <Button variant="outline-primary" onClick={() => handleActionClick("MERGE")} disabled={loading}>
          Merge
        </Button>
        <Button variant="outline-success" onClick={() => handleActionClick("MARK_GOOD")} disabled={loading}>
          Mark as Good
        </Button>
        <Button variant="outline-danger" onClick={() => handleActionClick("MARK_ERROR")} disabled={loading}>
          Mark as Error
        </Button>
      </ButtonGroup>
    </>
  );
};

export default MergeStatementActionButtonGroup;
