import { Button, Modal } from "react-bootstrap";

const ModalBox = (props) => {
  const {
    show = false,
    title = "",
    body = "",
    onNegativeClick = () => {},
    onPositiveClick = () => {},
    isShowNegativeClick = true,
    isShowPositifClick = true,
    showCloseButtonModal = true,
    labelPositiveClick = "Yes",
    labelNegativeClick = "No",
    variantNegativeClick = "outline-secondary",
    variantPositiveClick = "outline-primary",
  } = props;

  const handlePositiveClick = (e) => {
    onPositiveClick(e);
    onNegativeClick(e);
  };

  return (
    <Modal show={show} onHide={onNegativeClick}>
      <Modal.Header closeButton={showCloseButtonModal}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {isShowNegativeClick && (
          <Button variant={variantNegativeClick} onClick={onNegativeClick}>
            {labelNegativeClick}
          </Button>
        )}
        {isShowPositifClick && (
          <Button variant={variantPositiveClick} onClick={handlePositiveClick}>
            {labelPositiveClick}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBox;
