import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast, ToastContainer } from "react-bootstrap";
import { faCheckCircle as iconSuccess, faX as iconError } from "@fortawesome/free-solid-svg-icons";
import { useNotification } from "contexts/notification";

const Notification = () => {
  const { Header, Body } = Toast;
  const { notifications, popNotification } = useNotification();

  return (
    <ToastContainer containerPosition="fixed" position="top-end" className="p-3 mt-5 notification-container">
      {notifications.map((notification) => {
        let headerStyle = "";
        if (notification.type?.toLowerCase() === "success") {
          headerStyle = "text-success";
        } else if (notification.type?.toLowerCase() === "error") {
          headerStyle = "text-danger";
        }

        return (
          <Toast
            key={notification.id}
            onClose={() => popNotification(notification.id)}
            show={true}
            autohide
            delay={5000}
            bg="white"
          >
            <Header>
              <strong className={`me-auto ${headerStyle}`}>
                {notification.type?.toLowerCase() === "success" ? (
                  <FontAwesomeIcon icon={iconSuccess} size="lg" />
                ) : notification.type?.toLowerCase() === "error" ? (
                  <FontAwesomeIcon icon={iconError} size="lg" />
                ) : (
                  ""
                )}
                <small className="px-1">{notification.type?.toUpperCase()}</small>
              </strong>
            </Header>
            <Body className={`text-left`}>{notification.message}</Body>
          </Toast>
        );
      })}
    </ToastContainer>
  );
};

export default Notification;
