import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Button, Form, Spinner, ButtonGroup } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { useNotification } from "contexts/notification";
import { useStatement } from "contexts/statement";
import { getStatementDS as getStatementDSApi } from "utils/api";
import { transformCameltoSnakeObject } from "utils/helper";

const StatementDSInfo = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotification();
  const { id } = useParams();
  const { getStatementDS, statementDS, putStatementSaveDS, loading } = useStatement();
  const [commandLoading, setCommandLoading] = useState(false);

  const getDetail = useCallback(async (currentId) => {
    await getStatementDS(currentId);
  }, []);

  const handlePrevNextDS = async (cmd) => {
    try {
      setCommandLoading(true);
      const { data } = await getStatementDSApi(id, { cmd });
      if (data?.data?.uuid) {
        navigate(`/statement-ds/${data?.data?.uuid}`);
      }
    } catch (err) {
      pushNotification("error", null, err);
    } finally {
      setCommandLoading(false);
    }
  };

  const handleCopyPageDetailToClipboard = () => {
    try {
      const text = JSON.stringify(formData);
      localStorage.setItem("page-detail-clipboard", text);
      pushNotification("success", "Copied");
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const handlePastePageDetailFromClipboard = () => {
    try {
      const text = localStorage.getItem("page-detail-clipboard");
      const data = JSON.parse(text);
      setFormData(data);
      pushNotification("success", "Pasted");
    } catch (e) {
      pushNotification("error", null, e);
    }
  };

  const PrevNextButton = () => {
    return loading ? (
      <Skeleton width={250} height={30} />
    ) : (
      <div className="d-flex align-items-center">
        <Button
          size="sm"
          variant="outline-secondary"
          as={Link}
          onClick={() => handlePrevNextDS("prev")}
          className="mx-2"
          disabled={commandLoading}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Prev DS
        </Button>
        <p className="px-2">{statementDS?.sequence || "-"}</p>
        <Button
          size="sm"
          variant="outline-secondary"
          as={Link}
          onClick={() => handlePrevNextDS("next")}
          className="mx-2"
          disabled={commandLoading}
        >
          Next DS <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {commandLoading && <Spinner animation="border" variant="primary" />}
      </div>
    );
  };

  const [formData, setFormData] = useState(null);
  const { Control: Input } = Form;
  const handleInputChange = (e, type) => {
    setFormData({
      ...formData,
      [type]: e.target.value,
    });
  };

  const handleInfoSave = async () => {
    const res = await putStatementSaveDS(id, transformCameltoSnakeObject(formData));
    if (res) await getDetail(id);
  };

  useEffect(() => {
    getDetail(id);
  }, [id]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      bankName: statementDS?.bankName,
      bankAccountNumber: statementDS?.bankAccountNumber,
      bankAccountName: statementDS?.bankAccountName,
      companyName: statementDS?.companyName,
      period: statementDS?.period,
    }));
  }, [statementDS]);

  return (
    <div className="info">
      <h4 className="mb-4">Statement DS Detail</h4>
      <Row className="mb-2">
        <Col xs={2}>
          <p>#</p>
        </Col>
        <Col xs={4} className="text-left">
          <PrevNextButton />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Total Page</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statementDS?.totalPageNumber || "-"}</p>}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Created At</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statementDS?.createdAt || "-"}</p>}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Updated At</p>
        </Col>
        <Col xs={4} className="text-left">
          {loading ? <Skeleton /> : <p>{statementDS?.updatedAt || "-"}</p>}
        </Col>
      </Row>
      <hr />
      <Row className="mb-2">
        <Col xs={2}>
          <p>Bank Name</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <Input type="text" value={formData?.bankName || ""} onChange={(e) => handleInputChange(e, "bankName")} />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Bank Account Name</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <Input
              type="text"
              value={formData?.bankAccountName || ""}
              onChange={(e) => handleInputChange(e, "bankAccountName")}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Bank Account Number</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <Input
              type="text"
              value={formData?.bankAccountNumber || ""}
              onChange={(e) => handleInputChange(e, "bankAccountNumber")}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Company Name</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <Input
              type="text"
              value={formData?.companyName || ""}
              onChange={(e) => handleInputChange(e, "companyName")}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2}>
          <p>Period</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton />
          ) : (
            <Input type="text" value={formData?.period || ""} onChange={(e) => handleInputChange(e, "period")} />
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mb-4">
        <Col xs={2}>
          <p>Information</p>
        </Col>
        <Col xs={6} className="text-left">
          {loading ? (
            <Skeleton height={250} />
          ) : (
            <Input
              as="textarea"
              value={JSON.stringify(statementDS?.information, null, 2)}
              rows={10}
              readOnly
              disabled
            />
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={{ offset: 2, span: 6 }} className="d-flex justify-content-between">
          <ButtonGroup className="">
            <Button variant="outline-secondary" onClick={() => handleCopyPageDetailToClipboard()}>
              Copy to Clipboard
            </Button>
            <Button variant="outline-success" onClick={() => handlePastePageDetailFromClipboard()}>
              Paste From Clipboard
            </Button>
          </ButtonGroup>
          <ButtonGroup className="">
            <Button variant="outline-primary" onClick={handleInfoSave} disabled={loading}>
              Save
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default StatementDSInfo;
