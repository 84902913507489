import { Row, Col, Card, CardBody } from "react-bootstrap";
import { useAuth } from "contexts/auth";

const AccountInfo = () => {
  const { user } = useAuth();
  return (
    <div className="mt-5">
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="col-md-8">
                <div className="mb-3">
                  <p className="card-text">
                    <strong>Nama:</strong>
                  </p>
                  <p>{user?.name}</p>
                </div>
                <div className="mb-3">
                  <p className="card-text">
                    <strong>UUID:</strong>
                  </p>
                  <p>{user?.uuid}</p>
                </div>
                <div className="mb-3">
                  <p className="card-text">
                    <strong>Email:</strong>
                  </p>
                  <p>{user?.username}</p>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AccountInfo;
